<template>
  <div class="ColorSelector">
    <ColorArea :width="600" :height="600" />
    <div class="Controls">
      <CSSColorInput
        :color="activeColor"
        @input="$store.commit('updateActiveColor', $event)"
      />

      <ColorSliderGroup
        v-for="(colorspace, name) in colorspaces"
        :key="name"
        :color="activeColor"
        :title="name"
        :colorspace="colorspace"
        @input="$store.commit('updateActiveColor', $event)"
      />
    </div>
    <ColorList />
  </div>
</template>

<script>
import { vec3 } from "gl-matrix";

import Color from "./ColorSpaces.js";

import ColorArea from "./ColorArea.vue";
import ColorSliderGroup from "./ColorSliderGroup.vue";
import ColorList from "./ColorList.vue";
import CSSColorInput from "./CSSColorInput.vue";

export default {
  name: "App",
  components: {
    ColorArea,
    ColorSliderGroup,
    ColorList,
    CSSColorInput,
  },
  data() {
    const xyz_space = new Color.XYZColors();
    const lab_space = new Color.LABColors();
    const lch_space = new Color.LCHColors();
    const rgb_space = new Color.SRGBColors();
    const white_space = new Color.LDHColors(
      lab_space.toColorPoint(vec3.fromValues(1, 0, 0))
    );
    const black_space = new Color.LDHColors(
      lab_space.toColorPoint(vec3.fromValues(0, 0, 0))
    );

    return {
      lab_space,
      xyz_space,
      colorspaces: {
        Lab: lab_space,
        Lch: lch_space,
        sRGB: rgb_space,
        "White contrast": white_space,
        "Black contrast": black_space,
      },
    };
  },
  computed: {
    activeColor() {
      return this.$store.getters.activeColor;
    },
  },
  created() {
    const color = new Color.ColorPoint();
    color.lab = vec3.fromValues(0.5, 0, 0);

    this.$store.commit("addColor", color);
    this.$store.commit("setActiveColor", color);
  },
};
</script>

<style lang="scss">
@import "settings.scss";
@import "fonts.scss";

* {
  box-sizing: border-box;
}

.input {
  @include border-control-inset;
  padding: 0.3em 0.7em 0.2em;
}

.ColorSelector {
  font-family: "Source Sans Pro", sans-serif;

  background-color: white;
  display: flex;
  max-height: 618px;

  .Controls {
    display: flex;
    flex-direction: column;
  }

  .SliderGroup,
  .ColorList,
  .CSSColorInput {
    @include block;
    @include border-control;
    margin: 0.5rem;
  }
}
</style>
