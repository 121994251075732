import { vec3 } from 'gl-matrix';

export default {
  'Black': vec3.fromValues(0, 0, 0),
  'Night': vec3.fromValues(12, 9, 10),
  'Gunmetal': vec3.fromValues(44, 53, 57),
  'Midnight': vec3.fromValues(43, 27, 23),
  'Charcoal': vec3.fromValues(52, 40, 44),
  'Dark Slate Grey': vec3.fromValues(37, 56, 60),
  'Oil': vec3.fromValues(59, 49, 49),
  'Black Cat': vec3.fromValues(65, 56, 57),
  'Iridium': vec3.fromValues(61, 60, 58),
  'Black Eel': vec3.fromValues(70, 62, 63),
  'Black Cow': vec3.fromValues(76, 70, 70),
  'Gray Wolf': vec3.fromValues(80, 74, 75),
  'Vampire Gray': vec3.fromValues(86, 80, 81),
  'Gray Dolphin': vec3.fromValues(92, 88, 88),
  'Carbon Gray': vec3.fromValues(98, 93, 93),
  'Ash Gray': vec3.fromValues(102, 99, 98),
  'Cloudy Gray': vec3.fromValues(109, 105, 104),
  'Smokey Gray': vec3.fromValues(114, 110, 109),
  'Gray': vec3.fromValues(115, 111, 110),
  'Granite': vec3.fromValues(131, 126, 124),
  'Battleship Gray': vec3.fromValues(132, 132, 130),
  'Gray Cloud': vec3.fromValues(182, 182, 180),
  'Gray Goose': vec3.fromValues(209, 208, 206),
  'Platinum': vec3.fromValues(229, 228, 226),
  'Metallic Silver': vec3.fromValues(188, 198, 204),
  'Blue Gray': vec3.fromValues(152, 175, 199),
  'Light Slate Gray': vec3.fromValues(109, 123, 141),
  'Slate Gray': vec3.fromValues(101, 115, 131),
  'Jet Gray': vec3.fromValues(97, 109, 126),
  'Mist Blue': vec3.fromValues(100, 109, 126),
  'Marble Blue': vec3.fromValues(86, 109, 126),
  'Slate Blue': vec3.fromValues(115, 124, 161),
  'Steel Blue': vec3.fromValues(72, 99, 160),
  'Blue Jay': vec3.fromValues(43, 84, 126),
  'Dark Slate Blue': vec3.fromValues(43, 56, 86),
  'Midnight Blue': vec3.fromValues(21, 27, 84),
  'Navy Blue': vec3.fromValues(0, 0, 128),
  'Blue Whale': vec3.fromValues(52, 45, 126),
  'Lapis Blue': vec3.fromValues(21, 49, 126),
  'Denim Dark Blue': vec3.fromValues(21, 27, 141),
  'Earth Blue': vec3.fromValues(0, 0, 160),
  'Cobalt Blue': vec3.fromValues(0, 32, 194),
  'Blueberry Blue': vec3.fromValues(0, 65, 194),
  'Sapphire Blue': vec3.fromValues(37, 84, 199),
  'Blue Eyes': vec3.fromValues(21, 105, 199),
  'Royal Blue': vec3.fromValues(43, 96, 222),
  'Blue Orchid': vec3.fromValues(31, 69, 252),
  'Blue Lotus': vec3.fromValues(105, 96, 236),
  'Light Slate Blue': vec3.fromValues(115, 106, 255),
  'Windows Blue': vec3.fromValues(53, 126, 199),
  'Glacial Blue Ice': vec3.fromValues(54, 139, 193),
  'Silk Blue': vec3.fromValues(72, 138, 199),
  'Blue Ivy': vec3.fromValues(48, 144, 199),
  'Blue Koi': vec3.fromValues(101, 158, 199),
  'Columbia Blue': vec3.fromValues(135, 175, 199),
  'Baby Blue': vec3.fromValues(149, 185, 199),
  'Light Steel Blue': vec3.fromValues(114, 143, 206),
  'Ocean Blue': vec3.fromValues(43, 101, 236),
  'Blue Ribbon': vec3.fromValues(48, 110, 255),
  'Blue Dress': vec3.fromValues(21, 125, 236),
  'Dodger Blue': vec3.fromValues(21, 137, 255),
  'Cornflower Blue': vec3.fromValues(100, 149, 237),
  'Sky Blue': vec3.fromValues(102, 152, 255),
  'Butterfly Blue': vec3.fromValues(56, 172, 236),
  'Iceberg': vec3.fromValues(86, 165, 236),
  'Crystal Blue': vec3.fromValues(92, 179, 255),
  'Deep Sky Blue': vec3.fromValues(59, 185, 255),
  'Denim Blue': vec3.fromValues(121, 186, 236),
  'Light Sky Blue': vec3.fromValues(130, 202, 250),
  'Day Sky Blue': vec3.fromValues(130, 202, 255),
  'Jeans Blue': vec3.fromValues(160, 207, 236),
  'Blue Angel': vec3.fromValues(183, 206, 236),
  'Pastel Blue': vec3.fromValues(180, 207, 236),
  'Sea Blue': vec3.fromValues(194, 223, 255),
  'Powder Blue': vec3.fromValues(198, 222, 255),
  'Coral Blue': vec3.fromValues(175, 220, 236),
  'Light Blue': vec3.fromValues(173, 223, 255),
  'Robin Egg Blue': vec3.fromValues(189, 237, 255),
  'Pale Blue Lily': vec3.fromValues(207, 236, 236),
  'Light Cyan': vec3.fromValues(224, 255, 255),
  'Water': vec3.fromValues(235, 244, 250),
  'AliceBlue': vec3.fromValues(240, 248, 255),
  'Azure': vec3.fromValues(240, 255, 255),
  'Light Slate': vec3.fromValues(204, 255, 255),
  'Light Aquamarine': vec3.fromValues(147, 255, 232),
  'Electric Blue': vec3.fromValues(154, 254, 255),
  'Aquamarine': vec3.fromValues(127, 255, 212),
  'Cyan or Aqua': vec3.fromValues(0, 255, 255),
  'Tron Blue': vec3.fromValues(125, 253, 254),
  'Blue Zircon': vec3.fromValues(87, 254, 255),
  'Blue Lagoon': vec3.fromValues(142, 235, 236),
  'Celeste': vec3.fromValues(80, 235, 236),
  'Blue Diamond': vec3.fromValues(78, 226, 236),
  'Tiffany Blue': vec3.fromValues(129, 216, 208),
  'Cyan Opaque': vec3.fromValues(146, 199, 199),
  'Blue Hosta': vec3.fromValues(119, 191, 199),
  'Northern Lights Blue': vec3.fromValues(120, 199, 199),
  'Medium Turquoise': vec3.fromValues(72, 204, 205),
  'Turquoise': vec3.fromValues(67, 198, 219),
  'Jellyfish': vec3.fromValues(70, 199, 199),
  'Blue green': vec3.fromValues(123, 204, 181),
  'Macaw Blue Green': vec3.fromValues(67, 191, 199),
  'Light Sea Green': vec3.fromValues(62, 169, 159),
  'Dark Turquoise': vec3.fromValues(59, 156, 156),
  'Sea Turtle Green': vec3.fromValues(67, 141, 128),
  'Medium Aquamarine': vec3.fromValues(52, 135, 129),
  'Greenish Blue': vec3.fromValues(48, 125, 126),
  'Grayish Turquoise': vec3.fromValues(94, 125, 126),
  'Beetle Green': vec3.fromValues(76, 120, 126),
  'Teal': vec3.fromValues(0, 128, 128),
  'Sea Green': vec3.fromValues(78, 137, 117),
  'Camouflage Green': vec3.fromValues(120, 134, 107),
  'Sage Green': vec3.fromValues(132, 139, 121),
  'Hazel Green': vec3.fromValues(97, 124, 88),
  'Venom Green': vec3.fromValues(114, 140, 0),
  'Fern Green': vec3.fromValues(102, 124, 38),
  'Dark Forest Green': vec3.fromValues(37, 65, 23),
  'Medium Sea Green': vec3.fromValues(48, 103, 84),
  'Medium Forest Green': vec3.fromValues(52, 114, 53),
  'Seaweed Green': vec3.fromValues(67, 124, 23),
  'Pine Green': vec3.fromValues(56, 124, 68),
  'Jungle Green': vec3.fromValues(52, 124, 44),
  'Shamrock Green': vec3.fromValues(52, 124, 23),
  'Medium Spring Green': vec3.fromValues(52, 128, 23),
  'Forest Green': vec3.fromValues(78, 146, 88),
  'Green Onion': vec3.fromValues(106, 161, 33),
  'Spring Green': vec3.fromValues(74, 160, 44),
  'Lime Green': vec3.fromValues(65, 163, 23),
  'Clover Green': vec3.fromValues(62, 160, 85),
  'Green Snake': vec3.fromValues(108, 187, 60),
  'Alien Green': vec3.fromValues(108, 196, 23),
  'Green Apple': vec3.fromValues(76, 196, 23),
  'Yellow Green': vec3.fromValues(82, 208, 23),
  'Kelly Green': vec3.fromValues(76, 197, 82),
  'Zombie Green': vec3.fromValues(84, 197, 113),
  'Frog Green': vec3.fromValues(153, 198, 142),
  'Green Peas': vec3.fromValues(137, 195, 92),
  'Dollar Bill Green': vec3.fromValues(133, 187, 101),
  'Dark Sea Green': vec3.fromValues(139, 179, 129),
  'Iguana Green': vec3.fromValues(156, 176, 113),
  'Avocado Green': vec3.fromValues(178, 194, 72),
  'Pistachio Green': vec3.fromValues(157, 194, 9),
  'Salad Green': vec3.fromValues(161, 201, 53),
  'Hummingbird Green': vec3.fromValues(127, 232, 23),
  'Nebula Green': vec3.fromValues(89, 232, 23),
  'Stoplight Go Green': vec3.fromValues(87, 233, 100),
  'Algae Green': vec3.fromValues(100, 233, 134),
  'Jade Green': vec3.fromValues(94, 251, 110),
  'Green': vec3.fromValues(0, 255, 0),
  'Emerald Green': vec3.fromValues(95, 251, 23),
  'Lawn Green': vec3.fromValues(135, 247, 23),
  'Chartreuse': vec3.fromValues(138, 251, 23),
  'Dragon Green': vec3.fromValues(106, 251, 146),
  'Mint green': vec3.fromValues(152, 255, 152),
  'Green Thumb': vec3.fromValues(181, 234, 170),
  'Light Jade': vec3.fromValues(195, 253, 184),
  'Tea Green': vec3.fromValues(204, 251, 93),
  'Green Yellow': vec3.fromValues(177, 251, 23),
  'Slime Green': vec3.fromValues(188, 233, 84),
  'Goldenrod': vec3.fromValues(237, 218, 116),
  'Harvest Gold': vec3.fromValues(237, 226, 117),
  'Sun Yellow': vec3.fromValues(255, 232, 124),
  'Yellow': vec3.fromValues(255, 255, 0),
  'Corn Yellow': vec3.fromValues(255, 243, 128),
  'Parchment': vec3.fromValues(255, 255, 194),
  'Cream': vec3.fromValues(255, 255, 204),
  'Lemon Chiffon': vec3.fromValues(255, 248, 198),
  'Cornsilk': vec3.fromValues(255, 248, 220),
  'Beige': vec3.fromValues(245, 245, 220),
  'Blonde': vec3.fromValues(251, 246, 217),
  'AntiqueWhite': vec3.fromValues(250, 235, 215),
  'Champagne': vec3.fromValues(247, 231, 206),
  'BlanchedAlmond': vec3.fromValues(255, 235, 205),
  'Vanilla': vec3.fromValues(243, 229, 171),
  'Tan Brown': vec3.fromValues(236, 229, 182),
  'Peach': vec3.fromValues(255, 229, 180),
  'Mustard': vec3.fromValues(255, 219, 88),
  'Rubber Ducky Yellow': vec3.fromValues(255, 216, 1),
  'Bright Gold': vec3.fromValues(253, 208, 23),
  'Golden brown': vec3.fromValues(234, 193, 23),
  'Macaroni and Cheese': vec3.fromValues(242, 187, 102),
  'Saffron': vec3.fromValues(251, 185, 23),
  'Beer': vec3.fromValues(251, 177, 23),
  'Cantaloupe': vec3.fromValues(255, 166, 47),
  'Bee Yellow': vec3.fromValues(233, 171, 23),
  'Brown Sugar': vec3.fromValues(226, 167, 111),
  'BurlyWood': vec3.fromValues(222, 184, 135),
  'Deep Peach': vec3.fromValues(255, 203, 164),
  'Ginger Brown': vec3.fromValues(201, 190, 98),
  'School Bus Yellow': vec3.fromValues(232, 163, 23),
  'Sandy Brown': vec3.fromValues(238, 154, 77),
  'Fall Leaf Brown': vec3.fromValues(200, 181, 96),
  'Orange Gold': vec3.fromValues(212, 160, 23),
  'Sand': vec3.fromValues(194, 178, 128),
  'Cookie Brown': vec3.fromValues(199, 163, 23),
  'Caramel': vec3.fromValues(198, 142, 23),
  'Brass': vec3.fromValues(181, 166, 66),
  'Khaki': vec3.fromValues(173, 169, 110),
  'Camel brown': vec3.fromValues(193, 154, 107),
  'Bronze': vec3.fromValues(205, 127, 50),
  'Tiger Orange': vec3.fromValues(200, 129, 65),
  'Cinnamon': vec3.fromValues(197, 137, 23),
  'Bullet Shell': vec3.fromValues(175, 155, 96),
  'Dark Goldenrod': vec3.fromValues(175, 120, 23),
  'Copper': vec3.fromValues(184, 115, 51),
  'Wood': vec3.fromValues(150, 111, 51),
  'Oak Brown': vec3.fromValues(128, 101, 23),
  'Moccasin': vec3.fromValues(130, 120, 57),
  'Army Brown': vec3.fromValues(130, 123, 96),
  'Sandstone': vec3.fromValues(120, 109, 95),
  'Mocha': vec3.fromValues(73, 61, 38),
  'Taupe': vec3.fromValues(72, 60, 50),
  'Coffee': vec3.fromValues(111, 78, 55),
  'Brown Bear': vec3.fromValues(131, 92, 59),
  'Red Dirt': vec3.fromValues(127, 82, 23),
  'Sepia': vec3.fromValues(127, 70, 44),
  'Orange Salmon': vec3.fromValues(196, 116, 81),
  'Rust': vec3.fromValues(195, 98, 65),
  'Red Fox': vec3.fromValues(195, 88, 23),
  'Chocolate': vec3.fromValues(200, 90, 23),
  'Sedona': vec3.fromValues(204, 102, 0),
  'Papaya Orange': vec3.fromValues(229, 103, 23),
  'Halloween Orange': vec3.fromValues(230, 108, 44),
  'Pumpkin Orange': vec3.fromValues(248, 114, 23),
  'Construction Cone Orange': vec3.fromValues(248, 116, 49),
  'Sunrise Orange': vec3.fromValues(230, 116, 81),
  'Mango Orange': vec3.fromValues(255, 128, 64),
  'Dark Orange': vec3.fromValues(248, 128, 23),
  'Coral': vec3.fromValues(255, 127, 80),
  'Basket Ball Orange': vec3.fromValues(248, 129, 88),
  'Light Salmon': vec3.fromValues(249, 150, 107),
  'Tangerine': vec3.fromValues(231, 138, 97),
  'Dark Salmon': vec3.fromValues(225, 139, 107),
  'Light Coral': vec3.fromValues(231, 116, 113),
  'Bean Red': vec3.fromValues(247, 93, 89),
  'Valentine Red': vec3.fromValues(229, 84, 81),
  'Shocking Orange': vec3.fromValues(229, 91, 60),
  'Red': vec3.fromValues(255, 0, 0),
  'Scarlet': vec3.fromValues(255, 36, 0),
  'Ruby Red': vec3.fromValues(246, 34, 23),
  'Ferrari Red': vec3.fromValues(247, 13, 26),
  'Fire Engine Red': vec3.fromValues(246, 40, 23),
  'Lava Red': vec3.fromValues(228, 34, 23),
  'Love Red': vec3.fromValues(228, 27, 23),
  'Grapefruit': vec3.fromValues(220, 56, 31),
  'Chestnut Red': vec3.fromValues(195, 74, 44),
  'Cherry Red': vec3.fromValues(194, 70, 65),
  'Mahogany': vec3.fromValues(192, 64, 0),
  'Chilli Pepper': vec3.fromValues(193, 27, 23),
  'Cranberry': vec3.fromValues(159, 0, 15),
  'Red Wine': vec3.fromValues(153, 0, 18),
  'Burgundy': vec3.fromValues(140, 0, 26),
  'Chestnut': vec3.fromValues(149, 69, 53),
  'Blood Red': vec3.fromValues(126, 53, 23),
  'Sienna': vec3.fromValues(138, 65, 23),
  'Sangria': vec3.fromValues(126, 56, 23),
  'Firebrick': vec3.fromValues(128, 5, 23),
  'Maroon': vec3.fromValues(129, 5, 65),
  'Plum Pie': vec3.fromValues(125, 5, 65),
  'Velvet Maroon': vec3.fromValues(126, 53, 77),
  'Plum Velvet': vec3.fromValues(125, 5, 82),
  'Rosy Finch': vec3.fromValues(127, 78, 82),
  'Puce': vec3.fromValues(127, 90, 88),
  'Dull Purple': vec3.fromValues(127, 82, 93),
  'Rosy Brown': vec3.fromValues(179, 132, 129),
  'Khaki Rose': vec3.fromValues(197, 144, 142),
  'Pink Bow': vec3.fromValues(196, 129, 137),
  'Lipstick Pink': vec3.fromValues(196, 135, 147),
  'Rose': vec3.fromValues(232, 173, 170),
  'Rose Gold': vec3.fromValues(236, 197, 192),
  'Desert Sand': vec3.fromValues(237, 201, 175),
  'Pig Pink': vec3.fromValues(253, 215, 228),
  'Cotton Candy': vec3.fromValues(252, 223, 255),
  'Pink Bubble Gum': vec3.fromValues(255, 223, 221),
  'Misty Rose': vec3.fromValues(251, 187, 185),
  'Pink': vec3.fromValues(250, 175, 190),
  'Light Pink': vec3.fromValues(250, 175, 186),
  'Flamingo Pink': vec3.fromValues(249, 167, 176),
  'Pink Rose': vec3.fromValues(231, 161, 176),
  'Pink Daisy': vec3.fromValues(231, 153, 163),
  'Cadillac Pink': vec3.fromValues(227, 138, 174),
  'Carnation Pink': vec3.fromValues(247, 120, 161),
  'Blush Red': vec3.fromValues(229, 110, 148),
  'Hot Pink': vec3.fromValues(246, 96, 171),
  'Watermelon Pink': vec3.fromValues(252, 108, 133),
  'Violet Red': vec3.fromValues(246, 53, 138),
  'Deep Pink': vec3.fromValues(245, 40, 135),
  'Pink Cupcake': vec3.fromValues(228, 94, 157),
  'Pink Lemonade': vec3.fromValues(228, 40, 124),
  'Neon Pink': vec3.fromValues(245, 53, 170),
  'Magenta': vec3.fromValues(255, 0, 255),
  'Dimorphotheca Magenta': vec3.fromValues(227, 49, 157),
  'Bright Neon Pink': vec3.fromValues(244, 51, 255),
  'Pale Violet Red': vec3.fromValues(209, 101, 135),
  'Tulip Pink': vec3.fromValues(194, 90, 124),
  'Medium Violet Red': vec3.fromValues(202, 34, 107),
  'Rogue Pink': vec3.fromValues(193, 40, 105),
  'Burnt Pink': vec3.fromValues(193, 34, 103),
  'Bashful Pink': vec3.fromValues(194, 82, 131),
  'Dark Carnation Pink': vec3.fromValues(193, 34, 131),
  'Plum': vec3.fromValues(185, 59, 143),
  'Viola Purple': vec3.fromValues(126, 88, 126),
  'Purple Iris': vec3.fromValues(87, 27, 126),
  'Plum Purple': vec3.fromValues(88, 55, 89),
  'Indigo': vec3.fromValues(75, 0, 130),
  'Purple Monster': vec3.fromValues(70, 27, 126),
  'Purple Haze': vec3.fromValues(78, 56, 126),
  'Eggplant': vec3.fromValues(97, 64, 81),
  'Grape': vec3.fromValues(94, 90, 128),
  'Purple Jam': vec3.fromValues(106, 40, 126),
  'Dark Orchid': vec3.fromValues(125, 27, 126),
  'Purple Flower': vec3.fromValues(167, 74, 199),
  'Medium Orchid': vec3.fromValues(176, 72, 181),
  'Purple Amethyst': vec3.fromValues(108, 45, 199),
  'Dark Violet': vec3.fromValues(132, 45, 206),
  'Violet': vec3.fromValues(141, 56, 201),
  'Purple Sage Bush': vec3.fromValues(122, 93, 199),
  'Lovely Purple': vec3.fromValues(127, 56, 236),
  'Purple': vec3.fromValues(142, 53, 239),
  'Aztech Purple': vec3.fromValues(137, 59, 255),
  'Medium Purple': vec3.fromValues(132, 103, 215),
  'Jasmine Purple': vec3.fromValues(162, 59, 236),
  'Purple Daffodil': vec3.fromValues(176, 65, 255),
  'Tyrian Purple': vec3.fromValues(196, 90, 236),
  'Crocus Purple': vec3.fromValues(145, 114, 236),
  'Purple Mimosa': vec3.fromValues(158, 123, 255),
  'Heliotrope Purple': vec3.fromValues(212, 98, 255),
  'Crimson': vec3.fromValues(226, 56, 236),
  'Purple Dragon': vec3.fromValues(195, 142, 199),
  'Lilac': vec3.fromValues(200, 162, 200),
  'Blush Pink': vec3.fromValues(230, 169, 236),
  'Mauve': vec3.fromValues(224, 176, 255),
  'Wisteria Purple': vec3.fromValues(198, 174, 199),
  'Blossom Pink': vec3.fromValues(249, 183, 255),
  'Thistle': vec3.fromValues(210, 185, 211),
  'Periwinkle': vec3.fromValues(233, 207, 236),
  'Lavender Pinocchio': vec3.fromValues(235, 221, 226),
  'Lavender blue': vec3.fromValues(227, 228, 250),
  'Pearl': vec3.fromValues(253, 238, 244),
  'SeaShell': vec3.fromValues(255, 245, 238),
  'Milk White': vec3.fromValues(254, 252, 255),
  'White': vec3.fromValues(255, 255, 255)
};
