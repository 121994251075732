<template>
  <div class="ColorList">
    <div
      v-for="(color, idx) in colors"
      :key="idx"
      class="Color"
      :class="{ Active: color === activeColor }"
      :style="{
        backgroundColor: color.css,
        color: color.lab[0] >= 0.5 ? 'black' : 'white',
      }"
      @click="selectColor(color)"
    >
      <a
        style="cursor: pointer; text-decoration: underline"
        @click="addNamedNeighbor(color)"
      >
        {{ color.name }} </a
      ><br />
      Δ = {{ distance(color) }}, ⬓ = {{ contrast(color) }}
      <button class="Remove" @click.stop="removeEntry(color)">×</button>
    </div>
    <button class="Add" @click="addEntry()">+</button>
    <pre class="Code">{{ scssCode }}</pre>
  </div>
</template>

<script>
/**
 * Calculate the perceived contrast between two colors
 *
 * Original Source: https://github.com/antimatter15/rgb-lab/blob/master/color.js
 * Adapted to work with Lch values directly
 */
function deltaE(lchA, lchB) {
  const deltaL = lchA[0] - lchB[0];
  const deltaCkcsc = (lchA[1] - lchB[1]) / (1.0 + 0.045 * lchA[1]);
  const deltaHkhsh =
    Math.sqrt(2 * lchA[1] * lchB[1] * (1 - Math.cos(lchA[2] - lchB[2]))) /
    (1.0 + 0.015 * lchA[1]);
  return Math.sqrt(
    deltaL * deltaL + deltaCkcsc * deltaCkcsc + deltaHkhsh * deltaHkhsh
  );
}

export default {
  name: "ColorList",
  computed: {
    colors() {
      return this.$store.getters.colors;
    },
    activeColor() {
      return this.$store.getters.activeColor;
    },
    scssCode() {
      var round_snd = ([key, value]) => [key, Math.round(value * 100) / 100];
      var colors = { color: [] };
      for (const color of this.colors) {
        const [l, c] = color.lch;

        /** Characterising the color with some ad-hoc weight-functions */
        const character = [
          ["hue", c - 0.5 * Math.max(l - 0.5, 0.5 - l)],
          ["light", 2 * (l - 0.5)],
          ["dark", 2 * (0.5 - l)],
          ["pale", 1 - Math.sqrt(c)],
        ]
          .map(round_snd)
          .sort((entry) => -entry[1]);

        colors.color.push([color, character]);
      }

      var name_cats = {
        hue: [""],
        light: ["light", "ligther", "lightest"],
        dark: ["dark", "darker", "darkest"],
        pale: ["normal", "pale", "paler", "palest"],
      };

      var name_color = (value, domain, name_list) => {
        const order = domain.indexOf(value);
        if (order >= 0) {
          if (domain.length >= name_list.length) {
            return "_" + name_list[0] + String(order + 1);
          } else {
            return "_" + name_list[order];
          }
        }
        return "";
      };

      var names = [];

      for (let idx = 0; Object.keys(colors).length > 0 && idx < 5; idx++) {
        for (const [prefix, color_group] of Object.entries(colors)) {
          if (
            color_group.length === 1 ||
            (color_group.length > 1 && color_group[0][1].length === 0)
          ) {
            names.push([prefix, color_group[0][0]]);
            delete colors[prefix];
            continue;
          }

          const values = {};
          for (const [color, character] of color_group) {
            let [key, value] = character[0];
            if (key === "hue") {
              value = Math.round(color.lch[2] * 100) / 100;
              character[0][1] = value;
            }

            if (values[key] == null) {
              values[key] = new Set();
            }
            values[key].add(value);
          }

          for (const [key, set] of Object.entries(values)) {
            values[key] = [...set].sort();
          }

          const new_colors = {};
          for (const [color, character] of color_group) {
            const [key, value] = character.shift();
            const name =
              prefix + name_color(value, values[key], name_cats[key]);

            if (new_colors[name] == null) {
              new_colors[name] = [];
            }

            new_colors[name].push([color, character]);
          }

          if (Object.keys(new_colors).length > 1) {
            Object.assign(colors, new_colors);
            delete colors[prefix];
          }
        }
      }

      names.sort();

      var text = "";
      for (const [name, color] of names) {
        text += "$" + name + ": " + color.css + ";\n";
      }
      return text;
    },
  },
  methods: {
    selectColor(color) {
      this.$store.commit("setActiveColor", color);
    },
    addEntry() {
      const color = this.activeColor.clone();
      this.$store.commit("addColor", color);
      this.$store.commit("setActiveColor", color);
    },
    removeEntry(color) {
      if (this.colors.length > 1) {
        this.$store.commit("removeColor", color);
      }
    },
    addNamedNeighbor(color) {
      const namedColor = color.namedNeighbor;
      this.$store.commit("addColor", namedColor);
      this.$store.commit("setActiveColor", namedColor);
    },
    distance(color) {
      if (!this.activeColor) {
        return null;
      }

      const ref = this.activeColor.lch;
      return Math.round(deltaE(ref, color.lch) * 100) / 100;
    },
    contrast(color) {
      if (!this.activeColor) {
        return null;
      }

      const y1 = this.activeColor.xyz[1];
      const y2 = color.xyz[1];
      return (
        Math.round(
          ((Math.max(y1, y2) + 0.05) / (Math.min(y1, y2) + 0.05)) * 10
        ) /
          10 +
        ":1"
      );
    },
  },
};
</script>

<style lang="scss">
@import "settings.scss";

.ColorList {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  flex-grow: 1;
  align-content: flex-start;

  .Color,
  .Add {
    box-sizing: border-box;
    height: 3.1rem;
    margin-bottom: 0.5rem;
    margin-right: 0.5rem;
    overflow: hidden;
    position: relative;
    width: 10rem;
  }

  .Color {
    padding: 0.25em 0.5em;
  }

  .Active {
    outline: 1px dashed $primary;
  }

  .Remove {
    background: transparent;
    border: 1px solid $primary;
    color: inherit;
    font-size: 2rem;
    line-height: 0.8ex;
    position: absolute;
    right: 0;
    top: 0;
    padding: 0 0 0.5ex;
  }

  .Add,
  .Code {
    background: $stripes;
    border: none;
  }

  .Add {
    display: block;
    font-size: 2rem;
  }
}
</style>
