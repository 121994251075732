import { vec3 } from "gl-matrix";
import Color from "./ColorSpaces.js";

const measurePerformance = true;

/**
 * @param CanvasRenderingContext2D context
 */
export default function renderToContext(
  context,
  coordinates,
  width,
  height,
  pivot
) {
  const xyz_space = new Color.XYZColors();

  if (measurePerformance) {
    console.time("doRender");
  }
  const rgb_precision = 0.005;
  const hue_max_precision = 0.01;
  const hue_precision_levels = 11;
  const min_wavelength = 400;
  const max_wavelength = 660;

  const pivot_y = pivot[1];

  context.save();

  context.clearRect(0, 0, width, height);

  /* Draw location of monochromatic light (outside of sRGB gamut) */

  context.beginPath();

  let initialized = false;
  const monochromatic_image = [];
  for (
    let wavelength = min_wavelength;
    wavelength <= max_wavelength;
    wavelength++
  ) {
    const [x, y, z] = Color.monochromatic_XYZ[wavelength];
    const scale = pivot_y / y;
    const position = coordinates.getPosition(
      coordinates.color_space.fromXYZ(
        vec3.fromValues(x * scale, pivot_y, z * scale)
      )
    );
    monochromatic_image[wavelength] = position;
    if (initialized) {
      context.lineTo(...position);
    } else {
      context.moveTo(...position);
      initialized = true;
    }
  }

  context.closePath();

  context.fillStyle = "#000";
  context.fill();

  if (measurePerformance) {
    console.timeLog("doRender");
  }

  /** Find boundary of sRGB gamut */

  const RBtoColor = (r, b) => {
    const g = xyz_space.gamma_correction(
      (pivot_y -
        0.21258623 * xyz_space.gamma_decorrection(r) -
        0.0722005 * xyz_space.gamma_decorrection(b)) /
        0.7151703
    );
    if (g < 0 || g > 1) {
      return null;
    }
    return coordinates.color_space.fromSRGB(vec3.fromValues(r, g, b));
  };
  const GBtoColor = (g, b) => {
    const r = xyz_space.gamma_correction(
      (pivot_y -
        0.7151703 * xyz_space.gamma_decorrection(g) -
        0.0722005 * xyz_space.gamma_decorrection(b)) /
        0.21258623
    );
    if (r < 0 || r > 1) {
      return null;
    }
    return coordinates.color_space.fromSRGB(vec3.fromValues(r, g, b));
  };

  const rgb_edges = [[], [], [], [], [], []];
  for (let tick = 0; tick < 1 + rgb_precision; tick += rgb_precision) {
    rgb_edges[0].push(RBtoColor(0, tick));
    rgb_edges[1].push(RBtoColor(1, tick));
    rgb_edges[2].push(RBtoColor(tick, 0));
    rgb_edges[3].push(RBtoColor(tick, 1));
    rgb_edges[4].push(GBtoColor(0, tick));
    rgb_edges[5].push(GBtoColor(1, tick));
  }

  if (measurePerformance) {
    console.timeLog("doRender");
  }

  const hue_map = new Map();
  for (const edge of rgb_edges) {
    context.beginPath();
    initialized = false;
    for (const lab of edge) {
      if (lab == null) {
        continue;
      }

      const hue = Math.atan2(lab[2], lab[1]);
      for (
        let idx = 0, precision = hue_max_precision;
        idx < hue_precision_levels;
        idx++, precision *= 2
      ) {
        const approx = Math.round(hue / precision) * precision;
        if (hue_map.has(approx)) {
          break;
        }
        hue_map.set(approx, lab);
      }
    }
  }

  if (measurePerformance) {
    console.timeLog("doRender");
  }

  /** Draw Lab colors */

  const image_data = context.getImageData(0, 0, width, height);

  for (let x = 0; x < width; x++) {
    for (let y = 0; y < height; y++) {
      const offset = (y * width + x) * 4;

      if (image_data.data[offset + 3] === 0) {
        continue;
      }

      const color = coordinates.getColor(x, y);
      const hue = Math.atan2(color[2], color[1]);
      coordinates.color_space.toSRGB(color);

      image_data.data[offset + 3] = 255;

      if (
        color[0] < 0 ||
        color[0] > 1 ||
        color[1] < 0 ||
        color[1] > 1 ||
        color[2] < 0 ||
        color[2] > 1
      ) {
        let lab2;

        for (
          let idx = 0, precision = hue_max_precision;
          idx < hue_precision_levels;
          idx++, precision *= 2
        ) {
          lab2 = hue_map.get(Math.round(hue / precision) * precision);
          if (lab2) {
            break;
          }
        }

        if (lab2) {
          vec3.copy(color, lab2);
          coordinates.color_space.toSRGB(color);
        } else {
          image_data.data[offset + 3] = 64;
        }
      }

      image_data.data[offset] = color[0] * 255;
      image_data.data[offset + 1] = color[1] * 255;
      image_data.data[offset + 2] = color[2] * 255;
    }
  }
  context.putImageData(image_data, 0, 0);

  if (measurePerformance) {
    console.timeLog("doRender");
  }

  /** Draw ticks on monochromatic range */

  const tick_length = 5;
  const tick_font_size = 2 * tick_length;
  context.beginPath();
  context.font = String(tick_font_size) + "px Source Sans Pro, sans-serif";
  for (
    let wavelength = Math.ceil((min_wavelength + 1) / 10) * 10;
    wavelength < max_wavelength;
    wavelength += 10
  ) {
    const current = monochromatic_image[wavelength];
    if (
      current[0] < 0 ||
      current[0] > width ||
      current[1] < 0 ||
      current[1] > width
    ) {
      continue;
    }

    const previous = monochromatic_image[wavelength - 1];
    const next = monochromatic_image[wavelength + 1];

    const direction =
      Math.atan2(next[1] - previous[1], next[0] - previous[0]) + Math.PI / 2;

    context.save();
    context.translate(...current);
    context.rotate(direction);

    context.moveTo(0, tick_font_size / 2);
    context.lineTo(tick_length, tick_font_size / 2);

    context.fillText(
      String(wavelength) + " nm",
      tick_length * 2,
      tick_font_size / 2
    );

    context.restore();
  }
  context.stroke();

  /** Draw boundary of sRGB gamut */

  for (const edge of rgb_edges) {
    context.beginPath();
    initialized = false;
    for (const lab of edge) {
      if (lab == null) {
        continue;
      }
      const position = coordinates.getPosition(lab);
      if (initialized) {
        context.lineTo(...position);
      } else {
        context.moveTo(...position);
        initialized = true;
      }
    }
    context.stroke();
  }

  if (measurePerformance) {
    console.timeEnd("doRender");
  }
}
