import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    colors: [],
    activeColor: null,
  },
  getters: {
    colors(state) {
      return state.colors;
    },
    activeColor(state) {
      return state.colors[state.activeColor];
    },
  },
  mutations: {
    addColor(state, color) {
      state.colors.push(color);
    },
    removeColor(state, color) {
      const idx = state.colors.indexOf(color);
      state.colors.splice(idx, 1);

      if (state.colors.length === 0) {
        state.activeColor = null;
      } else {
        state.activeColor = Math.min(idx, state.colors.length - 1);
      }
    },
    updateActiveColor(state, color) {
      Vue.set(state.colors, state.activeColor, color);
    },
    setActiveColor(state, color) {
      const idx = state.colors.indexOf(color);
      if (idx !== -1) {
        state.activeColor = idx;
      }
    },
  },
  actions: {},
});
