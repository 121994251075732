<template>
  <div class="CSSColorInput">
    <label>CSS</label>
    <StubbornInput :value="color.css" class="input" @input="onCSSInput" />
    <canvas ref="canvas" width="1" height="1" style="display: none"></canvas>
  </div>
</template>

<script>
import { vec3 } from "gl-matrix";
import Color from "./ColorSpaces.js";
import StubbornInput from "./StubbornInput.vue";

export default {
  components: {
    StubbornInput,
  },
  props: {
    color: { type: Object, required: true },
  },
  methods: {
    onCSSInput(value) {
      const context = this.$refs.canvas.getContext("2d");
      context.fillStyle = value;
      context.fillRect(0, 0, 1, 1);

      const [r, g, b] = context.getImageData(0, 0, 1, 1).data;
      const color_point = new Color.ColorPoint();
      color_point.rgb = vec3.fromValues(r / 255, g / 255, b / 255);

      this.$emit("input", color_point);
    },
  },
};
</script>

<style lang="scss">
@import "settings.scss";

.CSSColorInput {
  display: flex;
  gap: 0.3em;

  input {
    width: 5.5em;
  }
}
</style>
