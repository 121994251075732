<template>
  <SliderGroup :channels="channels" :value="value" @input="onInput">
    <b>{{ title }}</b>
  </SliderGroup>
</template>

<script>
import Color from "./ColorSpaces.js";
import SliderGroup from "./SliderGroup.vue";

const minimalSteps = 100;

function roundDownToSignificant(value, digits) {
  if (!value) {
    return value;
  }

  const order = Math.pow(10, Math.floor(Math.log10(value)) - digits + 1);
  return Math.floor(value / order) * order;
}

export default {
  name: "ColorSliderGroup",
  components: { SliderGroup },
  model: {
    prop: "color",
    event: "input",
  },
  props: {
    title: { type: String, required: true },
    colorspace: { type: Color.ColorSpace, required: true },
    color: { type: Color.ColorPoint, required: true },
  },
  data() {
    const channels = [];
    for (const channel of this.colorspace.getChannels()) {
      const attr = this.colorspace.getChannelRange(channel);
      attr.step = roundDownToSignificant(
        (attr.max - attr.min) / minimalSteps,
        1
      );
      attr.min = Math.floor(attr.min / attr.step) * attr.step;
      attr.max = Math.ceil(attr.max / attr.step) * attr.step;
      attr.value = 0;
      channels.push({ label: channel, attr });
    }

    return {
      channels: channels,
    };
  },
  computed: {
    value() {
      if (this.color == null) {
        const value = [];
        for (const channel of this.channels) {
          value.push(channel.attr.min);
        }
        return value;
      } else {
        return Array.from(this.colorspace.fromColorPoint(this.color.clone()));
      }
    },
  },
  methods: {
    onInput(value) {
      this.$emit("input", this.colorspace.toColorPoint(value));
    },
  },
};
</script>
