<template>
  <div
    class="ColorMarker"
    :class="{ 'is-active': isActive }"
    :style="styles"
    @mousedown="$store.commit('setActiveColor', color)"
    @click.stop="$store.commit('setActiveColor', color)"
  ></div>
</template>

<script>
export default {
  props: {
    color: { type: Object, required: true },
    coordinates: { type: Object, required: true },
    isActive: { type: Boolean, default: false },
  },
  computed: {
    offset() {
      return this.coordinates.getPosition(
        this.coordinates.color_space.fromColorPoint(this.color)
      );
    },
    distance() {
      return this.coordinates.getNormalCoefficient(
        this.coordinates.color_space.fromColorPoint(this.color)
      );
    },
    radius() {
      return 1.5 / (1.5 + this.distance);
    },
    styles() {
      return {
        left: this.offset[0] + "px",
        top: this.offset[1] + "px",
        backgroundColor: this.isActive ? this.color.css : "transparent",
        "--ColorMarker-radius": this.radius * 0.7 + "rem",
        filter: "blur(" + Math.sqrt(Math.abs(this.distance)) * 1.6 + "px)",
      };
    },
  },
};
</script>

<style lang="scss">
@import "settings.scss";

.ColorMarker {
  --ColorMarker-radius: 0.7rem;

  position: absolute;

  box-sizing: border-box;
  height: calc(2 * var(--ColorMarker-radius));
  width: calc(2 * var(--ColorMarker-radius));
  margin: calc(0px - var(--ColorMarker-radius));

  border-color: black white black white;
  border-style: solid;
  border-radius: var(--ColorMarker-radius);
  border-width: 2px;

  &.is-active {
    animation: ColorMarker--rotate 3s infinite linear;
  }
}

@keyframes ColorMarker--rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
</style>
