<template>
  <div
    ref="mouseFrame"
    class="ColorArea"
    :style="{ width: width, height: height }"
    @click="onMouseClick"
    @mousemove="onMouseMove"
    @wheel="onMouseWheel"
  >
    <ABCanvas
      :width="width"
      :height="height"
      :coordinates="canvas_coords"
      :lightness="lightness"
    />
    <ColorMarker
      v-for="(color, idx) of colors"
      :key="idx"
      :color="color"
      :coordinates="canvas_coords"
      :is-active="color === activeColor"
    />
    <div class="ColorArea-zoomIndicator">×{{ Math.round(zoom * 10) / 10 }}</div>
  </div>
</template>

<script>
import Vue from "vue";
import { vec3 } from "gl-matrix";

import Color from "./ColorSpaces.js";
import ColorCoordinates from "./ColorCoordinates.js";

import ABCanvas from "./ABCanvas.vue";
import ColorMarker from "./ColorMarker.vue";

export default {
  name: "ColorArea",
  components: {
    ABCanvas,
    ColorMarker,
  },
  props: {
    width: { type: Number, required: true },
    height: { type: Number, required: true },
  },
  data() {
    const color_space = new Color.LABColors();

    return {
      color_space,
      zoom: 1.4,
    };
  },
  computed: {
    colors() {
      return this.$store.getters.colors;
    },
    activeColor() {
      return this.$store.getters.activeColor;
    },
    canvas_coords() {
      const a_range = this.color_space.getChannelRange("a");
      const b_range = this.color_space.getChannelRange("b");

      return Vue.observable(
        new ColorCoordinates(
          this.width,
          this.height,
          this.color_space,
          this.color_space.toColorPoint(
            vec3.fromValues(
              0,
              (a_range.min + a_range.max) / 2,
              (b_range.min + b_range.max) / 2
            )
          ),
          this.color_space.toColorPoint(
            vec3.fromValues(0, a_range.max / this.zoom, 0)
          ),
          this.color_space.toColorPoint(
            vec3.fromValues(0, 0, b_range.max / this.zoom)
          )
        )
      );
    },
    lightness() {
      return this.activeColor.lab[0];
    },
  },
  watch: {
    activeColor: {
      immediate: true,
      handler: function (color) {
        this.canvas_coords.translate(color.lab);
      },
    },
  },
  mounted() {
    this.offset = this.$refs.mouseFrame.getBoundingClientRect();
  },
  methods: {
    onMouseMove(event) {
      if (event.buttons === 1) {
        this.activeColorFromMouse();
      }
    },
    onMouseClick(event) {
      if (event.button === 0) {
        this.activeColorFromMouse();
      }
    },
    onMouseWheel(event) {
      if (event.deltaY > 0) {
        this.zoom *= 0.8;
      } else {
        this.zoom *= 1.25;
      }
    },
    activeColorFromMouse() {
      this.$store.commit(
        "updateActiveColor",
        this.canvas_coords.color_space.toColorPoint(
          this.canvas_coords.getColor(
            event.pageX - this.offset.left,
            event.pageY - this.offset.top
          )
        )
      );
    },
  },
};
</script>

<style lang="scss">
@import "settings.scss";

.ColorArea {
  @include border-control($primary_dark);

  position: relative;
  margin: 0.5rem;
  background: $stripes;

  &-zoomIndicator {
    position: absolute;
    right: 0.7em;
    bottom: 0.3em;
  }
}
</style>
