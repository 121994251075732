import { vec3, mat3 } from "gl-matrix";
import monochromatic from "./colors/monochromatic.js";
import colorNames from "./colors/names.js";

const Color = {};

/**
 * The following data describes the CIE XYZ coordinates of monochromatic light.
 * Source: http://cvrl.ioo.ucl.ac.uk/ciexyzpr.htm
 */

Color.monochromatic_XYZ = monochromatic;

Color.ColorSpace = class ColorSpace {};

const transformXYZtoSRGB = mat3.fromValues(
  3.2406,
  -0.9689,
  0.0557,
  -1.5372,
  1.8758,
  -0.204,
  -0.4986,
  0.0415,
  1.057
);
const transformSRGBtoXYZ = mat3.fromValues(
  0.41239559,
  0.21258623,
  0.01929722,
  0.35758343,
  0.7151703,
  0.11918386,
  0.18049265,
  0.0722005,
  0.95049713
);

/**
 * Transform colors between CIE-XYZ and sRGB
 *
 * All sRGB coordinates are given in a range of 0 to 1.
 *
 * Source: https://en.wikipedia.org/wiki/SRGB#Specification_of_the_transformation
 */
Color.XYZColors = class XYZColors extends Color.ColorSpace {
  fromColorPoint(color) {
    return color.xyz;
  }

  toColorPoint(xyz) {
    const color_point = new Color.ColorPoint();
    color_point.xyz = xyz;
    return color_point;
  }

  toSRGB(xyz) {
    const rgb = xyz;
    vec3.transformMat3(rgb, xyz, transformXYZtoSRGB);
    rgb[0] = this.gamma_correction(rgb[0]);
    rgb[1] = this.gamma_correction(rgb[1]);
    rgb[2] = this.gamma_correction(rgb[2]);
    return rgb;
  }

  fromSRGB(rgb) {
    const xyz = rgb;
    xyz[0] = this.gamma_decorrection(xyz[0]);
    xyz[1] = this.gamma_decorrection(xyz[1]);
    xyz[2] = this.gamma_decorrection(xyz[2]);
    vec3.transformMat3(xyz, xyz, transformSRGBtoXYZ);
    return xyz;
  }

  /**
   * Algorithm:
   * For small values (up to 0.0031308), multiply by 12.92.
   * For bigger values the formula is a lot more complicated:
   *  (1 + 0.055) * value ** (1 / 2.4) - 0.055
   * We approximate this for performance reasons as:
   *
   */
  gamma_correction(value) {
    if (value <= 0.0031308) {
      return value * 12.92;
    } else {
      return 1.055 * Math.pow(value, 1 / 2.4) - 0.055;
    }
  }

  gamma_decorrection(value) {
    if (value <= 0.04045) {
      return value / 12.92;
    } else {
      return Math.pow((value + 0.055) / 1.055, 2.4);
    }
  }

  getChannels() {
    return ["x", "y", "z"];
  }

  getChannelRange(/*channel*/) {
    return { min: 0, max: 1 };
  }
};

/**
 * Abstract color space class, which transforms colors to rgb by first
 * transforming to CIE-XYZ.
 */
class ColorsViaXYZ extends Color.ColorSpace {
  constructor() {
    super();
    this.xyz = new Color.XYZColors();
  }

  toSRGB(color) {
    return this.xyz.toSRGB(this.toXYZ(color));
  }

  fromSRGB(rgb) {
    return this.fromXYZ(this.xyz.fromSRGB(rgb));
  }
}

Color.SRGBColors = class SRGBColors extends Color.ColorSpace {
  constructor() {
    super();
    this.xyz = new Color.XYZColors();
  }

  fromColorPoint(color) {
    return color.rgb;
  }

  toColorPoint(rgb) {
    const color_point = new Color.ColorPoint();
    color_point.rgb = rgb;
    return color_point;
  }

  toXYZ(rgb) {
    return this.xyz.fromSRGB(rgb);
  }

  fromXYZ(xyz) {
    return this.xyz.toSRGB(xyz);
  }

  getChannels() {
    return ["R", "G", "B"];
  }

  getChannelRange(/*channel*/) {
    return { min: 0, max: 1 };
  }
};

/**
 * Transform colors between LAB and sRGB
 *
 * L varies between 0 and 1, while A and B vary between -1 and 1
 */
Color.LABColors = class LABColors extends ColorsViaXYZ {
  fromColorPoint(color) {
    return color.lab;
  }

  toColorPoint(lab) {
    const color_point = new Color.ColorPoint();
    color_point.lab = lab;
    return color_point;
  }

  toXYZ(lab) {
    const xyz = lab;
    const y_n = (lab[0] + 0.16) / 1.16;
    return vec3.set(
      xyz,
      this.denormalize(y_n + lab[1] / 5, 0.9505),
      this.denormalize(y_n, 1),
      this.denormalize(y_n - lab[2] / 2, 1.089)
    );
  }

  fromXYZ(xyz) {
    const lab = xyz;
    const x_n = this.normalize(xyz[0], 0.9505);
    const y_n = this.normalize(xyz[1], 1);
    const z_n = this.normalize(xyz[2], 1.089);
    return vec3.set(lab, 1.16 * y_n - 0.16, 5 * (x_n - y_n), 2 * (y_n - z_n));
  }

  normalize(value, max) {
    const t = value / max;
    if (t > 0.008856) {
      return Math.pow(t, 1 / 3);
    } else {
      return t / (3 * 0.0428) + 4 / 29;
    }
  }

  denormalize(value, max) {
    let t;
    if (value > 6 / 29) {
      t = Math.pow(value, 3);
    } else {
      t = 3 * 0.0428 * (value - 4 / 29);
    }
    return t * max;
  }

  getChannels() {
    return ["L", "a", "b"];
  }

  getChannelRange(channel) {
    if (channel === "L") {
      return { min: 0, max: 1 };
    } else {
      return { min: -1.5, max: 1.5 };
    }
  }
};

Color.LCHColors = class LCHColors extends ColorsViaXYZ {
  constructor() {
    super();
    this.lab = new Color.LABColors();
  }

  fromColorPoint(color) {
    return color.lch;
  }

  toColorPoint(lch) {
    const color_point = new Color.ColorPoint();
    color_point.lch = lch;
    return color_point;
  }

  toLAB(lch) {
    const lab = lch;
    return vec3.set(
      lab,
      lch[0],
      lch[1] * Math.cos(lch[2]),
      lch[1] * Math.sin(lch[2])
    );
  }

  fromLAB(lab) {
    const lch = lab;
    return vec3.set(
      lch,
      lab[0],
      Math.sqrt(Math.pow(lab[1], 2) + Math.pow(lab[2], 2)),
      Math.atan2(lab[2], lab[1])
    );
  }

  toXYZ(lch) {
    return this.lab.toXYZ(this.toLAB(lch));
  }

  fromXYZ(xyz) {
    return this.fromLAB(this.lab.fromXYZ(xyz));
  }

  getChannels() {
    return ["L", "c", "h"];
  }

  getChannelRange(channel) {
    if (channel === "L") {
      return { min: 0, max: 1 };
    } else if (channel === "c") {
      return { min: 0, max: 1 };
    } else if (channel === "h") {
      return { min: -Math.PI, max: Math.PI };
    }
  }
};

/**
 * This color space transforms the Lab space into sphere coordinates around a reference color.
 *
 * h is like in LCHColors, d describes the distance from the reference color and
 * l is the angle between the line to the reference color and the horizontal ab-plane. (azimuth)
 */
Color.LDHColors = class LDHColors extends ColorsViaXYZ {
  constructor(reference) {
    super();
    this.reference = reference;
    this.lch = new Color.LCHColors();
  }

  fromColorPoint(color) {
    return this.fromLCH(color.lch);
  }

  toColorPoint(lch) {
    const color_point = new Color.ColorPoint();
    color_point.lch = this.toLCH(lch);
    return color_point;
  }

  toLCH(ldh) {
    const lch = ldh;
    const ref = this.reference.lch;
    return vec3.set(
      lch,
      ref[0] + Math.sin(ldh[0]) * ldh[1],
      ref[1] + Math.cos(ldh[0]) * ldh[1],
      ldh[2]
    );
  }

  fromLCH(lch) {
    const ldh = lch;
    const ref = this.reference.lch;
    return vec3.set(
      ldh,
      Math.atan2(lch[0] - ref[0], lch[1] - ref[1]),
      Math.sqrt(Math.pow(lch[0] - ref[0], 2) + Math.pow(lch[1] - ref[1], 2)),
      lch[2]
    );
  }

  toXYZ(lch) {
    return this.lch.toXYZ(this.toLCH(lch));
  }

  fromXYZ(xyz) {
    return this.fromLCH(this.lch.fromXYZ(xyz));
  }

  getChannels() {
    return ["l", "d", "h"];
  }

  getChannelRange(channel) {
    if (channel === "l") {
      return { min: -Math.PI, max: Math.PI };
    } else if (channel === "d") {
      return { min: 0, max: Math.sqrt(2) };
    } else if (channel === "h") {
      return { min: -Math.PI, max: Math.PI };
    }
  }
};

/**
 * Represent a color in multiple color systems
 */
Color.ColorPoint = class ColorPoint {
  clone() {
    const color = new ColorPoint();
    color.xyz = this.xyz;
    return color;
  }

  get rgb() {
    if (this._rgb == null) {
      const xyz = this.xyz;
      if (xyz != null) {
        this._rgb = ColorPoint.xyz_space.toSRGB(vec3.clone(xyz));
      }
    }
    return this._rgb;
  }

  get lab() {
    if (this._lab == null) {
      if (this._lch != null) {
        this._lab = ColorPoint.lch_space.toLAB(vec3.clone(this._lch));
      } else {
        const xyz = this.xyz;
        if (xyz != null) {
          this._lab = ColorPoint.lab_space.fromXYZ(vec3.clone(xyz));
        }
      }
    }
    return this._lab;
  }

  get lch() {
    if (this._lch == null) {
      if (this._lab != null) {
        this._lch = ColorPoint.lch_space.fromLAB(vec3.clone(this._lab));
      } else {
        const xyz = this.xyz;
        if (xyz != null) {
          this._lch = ColorPoint.lch_space.fromXYZ(vec3.clone(xyz));
        }
      }
    }
    return this._lch;
  }

  get xyz() {
    if (this._xyz == null) {
      if (this._lab != null) {
        this._xyz = ColorPoint.lab_space.toXYZ(vec3.clone(this._lab));
      } else if (this._lch != null) {
        this._xyz = ColorPoint.lch_space.toXYZ(vec3.clone(this._lch));
      } else if (this._rgb != null) {
        this._xyz = ColorPoint.srgb_space.toXYZ(vec3.clone(this._rgb));
      }
    }
    return this._xyz;
  }

  get css() {
    const [r, g, b] = this.rgb;
    const format = (val) =>
      Math.floor(Math.max(0, Math.min(1, val)) * 255)
        .toString(16)
        .padStart(2, "0");
    return "#" + format(r) + format(g) + format(b);
  }

  get name() {
    const xyz = this.xyz;
    let closestDist = Infinity;
    let closestName = null;
    for (const name of Object.keys(Color.named_colors_XYZ)) {
      const dist = vec3.dist(xyz, Color.named_colors_XYZ[name]);
      if (dist < closestDist) {
        closestName = name;
        closestDist = dist;
      }
      if (closestDist === 0) {
        break;
      }
    }
    return closestName;
  }

  get namedNeighbor() {
    const neighbor = new ColorPoint();
    neighbor.xyz = Color.named_colors_XYZ[this.name];
    return neighbor;
  }

  set rgb(rgb) {
    this._rgb = vec3.clone(rgb);
    this._lab = null;
    this._lch = null;
    this._xyz = null;
  }

  set lab(lab) {
    this._rgb = null;
    this._lab = vec3.clone(lab);
    this._lch = null;
    this._xyz = null;
  }

  set lch(lch) {
    this._rgb = null;
    this._lab = null;
    this._lch = vec3.clone(lch);
    this._xyz = null;
  }

  set xyz(xyz) {
    this._rgb = null;
    this._lab = null;
    this._lch = null;
    this._xyz = vec3.clone(xyz);
  }
};

Color.ColorPoint.srgb_space = new Color.SRGBColors();
Color.ColorPoint.lab_space = new Color.LABColors();
Color.ColorPoint.lch_space = new Color.LCHColors();
Color.ColorPoint.xyz_space = new Color.XYZColors();

Color.named_colors_XYZ = {};
for (const name of Object.keys(colorNames)) {
  const srgb = vec3.create();
  vec3.scale(srgb, colorNames[name], 1 / 255);
  Color.named_colors_XYZ[name] = Color.ColorPoint.srgb_space.toXYZ(srgb);
}

export default Color;
