<template>
  <input :value="display" @input="onInput" />
</template>

<script>
export default {
  name: "StubbornInput",
  props: {
    value: { type: String, default: "" },
  },
  data() {
    return {
      display: "",
      changed: false,
    };
  },
  watch: {
    value() {
      if (this.changed) {
        this.changed = false;
      } else {
        this.display = this.value;
      }
    },
  },
  mounted() {
    this.display = this.value;
  },
  methods: {
    onInput(event) {
      this.changed = true;
      this.$emit("input", event.target.value);
    },
  },
};
</script>
