import { vec3, mat4 } from "gl-matrix";
import Color from "./ColorSpaces.js";

export default class ColorCoordinates {
  /**
   * Define affine color coordinates that map an x-y-plane into a color space
   *
   * @param {Number} width - horizontal size of the mapped plane
   * @param {Number} height - vertical size of the mapped plane
   * @param {Color.ColorSpace} color_space - 3d-color space being mapped to
   * @param {Color.ColorPoint} origin - image of [width/2, height/2]
   * @param {Color.ColorPoint} x_direction - image of [width, height/2]
   * @param {Color.ColorPoint} y_direction - image of [width/2, height]
   */
  constructor(width, height, color_space, origin, x_direction, y_direction) {
    if (height == null) {
      /** Deserialization */
      const data = width;
      this.color_space = new Color[data.color_space]();
      this.normal = data.normal;
      this.transform_matrix = data.transform_matrix;
      this.transform_offset = data.transform_offset;
    } else {
      this.color_space = color_space;

      const origin_vec = color_space.fromColorPoint(origin);
      const x_image = color_space.fromColorPoint(x_direction);
      const y_image = color_space.fromColorPoint(y_direction);

      vec3.subtract(x_image, x_image, origin_vec);
      vec3.subtract(y_image, y_image, origin_vec);

      this.normal = vec3.create();
      vec3.cross(this.normal, x_image, y_image);
      vec3.normalize(this.normal, this.normal);

      this.transform_offset = vec3.subtract(vec3.create(), origin_vec, x_image);
      vec3.subtract(this.transform_offset, this.transform_offset, y_image);

      this.transform_matrix = mat4.fromValues(
        ...x_image,
        0,
        ...y_image,
        0,
        ...this.normal,
        0,
        ...this.transform_offset,
        1
      );
      mat4.scale(
        this.transform_matrix,
        this.transform_matrix,
        vec3.fromValues(2 / width, 2 / height, 1)
      );
    }

    this.inverse_matrix = mat4.create();
    mat4.invert(this.inverse_matrix, this.transform_matrix);
  }

  serialize() {
    let color_space;
    for (const key of Object.keys(Color)) {
      if (
        typeof Color[key] === "function" &&
        this.color_space instanceof Color[key]
      ) {
        color_space = key;
      }
    }
    if (color_space == null) {
      throw Error("Invalid color space");
    }
    return {
      color_space,
      normal: this.normal,
      transform_matrix: this.transform_matrix,
      transform_offset: this.transform_offset,
    };
  }

  getColor(x, y) {
    const abc = vec3.fromValues(x, y, 0);
    vec3.transformMat4(abc, abc, this.transform_matrix);
    return abc;
  }

  getPosition(abc) {
    const xyz = vec3.create();
    vec3.transformMat4(xyz, abc, this.inverse_matrix);
    return [xyz[0], xyz[1]];
  }

  getNormalCoefficient(abc) {
    const vec = vec3.clone(this.transform_offset);
    vec3.subtract(vec, vec, abc);
    return vec3.dot(vec, this.normal);
  }

  /**
   * Translate the image of the coordinates along the normal direction
   * such that it contains the given color point.
   */
  translate(abc) {
    const normal = this.getNormalCoefficient(abc);
    const vec = vec3.scale(vec3.create(), this.normal, normal);
    this.transform_offset = vec3.subtract(vec, this.transform_offset, vec);

    this.transform_matrix[12] = this.transform_offset[0];
    this.transform_matrix[13] = this.transform_offset[1];
    this.transform_matrix[14] = this.transform_offset[2];
    mat4.invert(this.inverse_matrix, this.transform_matrix);
  }
}
