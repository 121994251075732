import QueueWorker from "./queue.worker.js";
import renderToContext from "./renderToContext.js";

class DataBuffer {
  constructor() {
    this.store = {};
  }

  has(key) {
    return this.store[key] !== undefined;
  }

  get(key, factory) {
    if (this.store[key] != null) {
      return this.store[key];
    } else {
      const value = factory();
      this.store[key] = value;
      return value;
    }
  }

  put(key, value) {
    this.store[key] = value;
  }

  clear() {
    this.store = {};
  }
}

class SamplingBuffer extends DataBuffer {
  constructor(precision) {
    super();
    this.precision = precision || 1 / 100;
  }

  getBucket(key) {
    return Math.round(key / this.precision);
  }

  has(key) {
    return super.has(this.getBucket(key));
  }

  get(key, factory) {
    return super.get(this.getBucket(key), factory);
  }

  getClosest(key) {
    key = this.getBucket(key);
    const keys = Object.keys(this.store);
    if (!keys.length) {
      return;
    }

    keys.sort((a, b) =>
      a === b ? 0 : Math.abs(a - key) >= Math.abs(b - key) ? 1 : -1
    );
    return super.get(keys[0]);
  }

  put(key, value) {
    super.put(this.getBucket(key), value);
  }
}

class Deferred {
  constructor() {
    this.promise = new Promise((resolve, reject) => {
      this.resolve = resolve;
      this.reject = reject;
    });
  }
}

class BaseRenderer {
  constructor(width, height, coordinates) {
    this.width = width;
    this.height = height;
    this.coordinates = coordinates;

    this.wait_to_draw_key = null;

    this.buffer = new SamplingBuffer();
  }

  async draw(pivot, context) {
    const key = pivot.lab[0];

    if (context) {
      this.wait_to_draw_key = key;

      if (!this.buffer.has(key)) {
        const closest_data = this.buffer.getClosest(key);
        if (closest_data) {
          const bitmap = await closest_data.promise;
          context.transferFromImageBitmap(await createImageBitmap(bitmap));
        }
      }
    }

    const bitmap = await this.buffer.get(key, () =>
      this.request_render(pivot, key)
    ).promise;

    if (this.wait_to_draw_key !== key) {
      return;
    }

    if (context) {
      context.transferFromImageBitmap(await createImageBitmap(bitmap));
      this.wait_to_draw_key = null;
    }
    this.wait_to_draw_key = null;
  }
}

/**
 * Setup multiple workers and send work to them in round-robin fashion.
 */
const WORKER_COUNT = 4;
class WorkerRenderer extends BaseRenderer {
  constructor(width, height, coordinates) {
    super(width, height, coordinates);

    this.workers = [];
    this.next_worker = 0;
    for (let idx = 0; idx < WORKER_COUNT; idx++) {
      const worker = new QueueWorker();
      worker.addEventListener("message", this.on_render_complete.bind(this));
      this.workers[idx] = worker;
    }
  }

  request_render(pivot, key) {
    this.coordinates.translate(
      this.coordinates.color_space.fromColorPoint(pivot)
    );

    const worker = this.workers[this.next_worker];
    worker.postMessage({
      key,
      pivot: pivot.xyz,
      coordinates: this.coordinates.serialize(),
      width: this.width,
      height: this.height,
    });

    this.next_worker = (this.next_worker + 1) % WORKER_COUNT;
    return new Deferred();
  }

  on_render_complete(msg) {
    this.buffer.get(msg.data.key).resolve(msg.data.bitmap);
  }
}

class MainThreadRenderer extends BaseRenderer {
  constructor(width, height, coordinates) {
    super(width, height, coordinates);

    this.canvas = document.createElement("canvas");
    this.canvas.width = width;
    this.canvas.height = height;
    this.context = this.canvas.getContext("2d");
  }

  request_render(pivot /*, key*/) {
    this.coordinates.translate(
      this.coordinates.color_space.fromColorPoint(pivot)
    );

    renderToContext(
      this.context,
      this.coordinates,
      this.width,
      this.height,
      pivot.xyz
    );

    return {
      promise: Promise.resolve(
        this.context.getImageData(0, 0, this.width, this.height)
      ),
    };
  }
}

let ColorSpaceRenderer = MainThreadRenderer;
if (typeof Worker !== "undefined" && typeof OffscreenCanvas !== "undefined") {
  let context;
  try {
    context = new OffscreenCanvas(1, 1).getContext("2d");
  } catch {
    context = null;
  }
  if (context) {
    ColorSpaceRenderer = WorkerRenderer;
    console.info("Using web worker and offscreen canvas for rendering");
  }
}
export { ColorSpaceRenderer };
