<template>
  <canvas ref="canvas" :width="width" :height="height"></canvas>
</template>

<script>
import { ColorSpaceRenderer } from "./Renderer.js";
import Color from "./ColorSpaces.js";

export default {
  props: {
    width: { type: Number, required: true },
    height: { type: Number, required: true },
    lightness: { type: Number, required: true },
    coordinates: { type: Object, required: true },
  },
  data() {
    return {
      context: null,
    };
  },
  computed: {
    renderer() {
      return new ColorSpaceRenderer(this.width, this.height, this.coordinates);
    },
  },
  watch: {
    lightness() {
      this.loadColorData();
    },
    renderer() {
      this.loadColorData();
    },
  },
  mounted() {
    this.context = this.$refs.canvas.getContext("bitmaprenderer");
    this.loadColorData();
  },
  methods: {
    loadColorData() {
      const pivot = new Color.ColorPoint();
      pivot.lab = [this.lightness, 0, 0];
      this.renderer.draw(pivot, this.context);
    },
  },
};
</script>
