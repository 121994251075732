<template>
  <details class="SliderGroup">
    <summary><slot></slot></summary>
    <div class="SliderGroup-body">
      <div
        v-for="(channel, key) in channels"
        :key="key"
        class="SliderGroup-row"
      >
        <label class="SliderGroup-label">{{ channel.label }}</label>
        <input
          v-model="display[key]"
          class="Slider"
          v-bind="channel.attr"
          type="range"
          @input="onInput()"
        />
        <input
          v-model="display[key]"
          class="SliderGroup-number input"
          :class="{ 'is-negative': display[key] < 0 }"
          v-bind="channel.attr"
          maxlength="6"
          @input="onInput()"
        />
      </div>
    </div>
  </details>
</template>

<script>
export default {
  name: "SliderGroup",
  props: {
    channels: { type: Array, required: true },
    value: { type: Array, required: true },
  },
  data() {
    const display = [];
    for (const channel of this.channels) {
      display.push(channel.attr.min || 0);
    }
    return {
      changed: false,
      display: display,
    };
  },
  watch: {
    value() {
      if (this.changed) {
        this.changed = false;
      } else {
        this.updateDisplay();
      }
    },
  },
  mounted() {
    this.updateDisplay();
  },
  methods: {
    updateDisplay() {
      if (this.value != null) {
        const factor = Math.pow(10, 3);
        for (let idx = 0; idx < this.value.length; idx++) {
          this.$set(
            this.display,
            idx,
            Math.round(this.value[idx] * factor) / factor
          );
        }
      }
    },
    onInput() {
      this.changed = true;

      const value = this.display.map(parseFloat);
      this.$emit("input", value);
    },
  },
};
</script>

<style lang="scss">
@import "settings.scss";

.SliderGroup {
  &-body {
    display: flex;
    flex-direction: column;
    gap: 0.2em;
    margin-top: 0.3em;
  }

  &-row {
    display: flex;
    gap: 0.3em;
  }

  &-label {
    margin: 0.3em 0.1em 0.2em;
    width: 0.6em;
  }

  &-number {
    width: 4em;

    &.is-negative {
      padding-left: 0.355em;
    }
  }
}
</style>
